html{
  background-color: #48495B;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

h1{
  color: white;
  font-size: 30px;
  font-weight: 700;
  font-family: Arial;
}

h2{
  color: white;
  font-weight: 200;
  font-family: Arial;
}

h3{
  font-family: 'Roboto';
  color: white;
}

h4{
  color: white;
  font-family: 'Roboto';
}

h5{
  margin-top: 10px;
  margin-bottom: 10px;
  color: #383838;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial;
}

p{
  color: white;
  font-family: 'Roboto';
}

input[type="text"]{
  padding: 12px 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
}

hr{
  margin-top: 20px;
  margin-bottom: 20px;
}

header{
  z-index: 1;
  position: fixed;
  width: 100%;
  background-color: #48495B;
  height: 100px;
}

.navbar-icon{
  height: 60%;
  /* width: 60%; */
}

.navbar-toggle{
  background-color: #D67F0D;
  height: 100%;
  padding: 0px 14px;
  /* width: 15vw; */
  display: none;
  left: 0;
  border: none;
  position: absolute;
}

.navbar {
  background-color: #D67F0D;
  font-size: 0;
  height: 60px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.navbutton{
  background-color: transparent;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  border: none;
  width: 20%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.navbutton:hover{
  background-color: orange;
}

.navbutton:active{
  background-color: #D67F0D;
}

.center-text{
  width: 100%;
  text-align: center; 
}

.header-norm{
  width: 100%;
  text-align: center;
}

.header-mobile{
  display: none;
  width: 100%;
  /* text-align: center; */
}

video{
  object-fit: cover;
  position: fixed;
}

.home-images{
  width: 30%;
  min-height: 100%;
  padding-right: 2%;
  object-fit: cover;
}

.copyright{
  position: absolute;
  background-color: #D67F0D;;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.quote-box button:hover {
  background-color: orange;
}

.quote-box button:active {
  background-color: #D67F0D;;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);   
}

.quote-box button{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition-duration: 500ms;
  width: 60%;
  height: 50px;
  margin-top: 15px;
  padding: 10px;
  border: none;
  color: white;
  background-color: #D67F0D;;
  border-radius: 20px;
  font-family: 'Roboto';
  font-size: 18px;
}

.submit-button{
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.small-box{
  width: 100%;
  font-size: 12px;
  font-family: Arial;
}

.last-box{
  padding: 12px 20px;
  border-radius: 6px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  font-size: 12px;
  font-family: Arial;
  width: 100%;
  height: 100px;
}

.ext-int-header{
  padding-top: 160px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #48495B;
}

.ext-int-header h1{
  font-size: 50px;
  padding: 2% 3.5% 2% 3.5%;
}

.ext-int-description{
  padding: 50px;
  width: 50%;
}

.graphic-bar{
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  height: 60%;
  margin: 10% 0% 5% 0%;
}

.graphic-bar-img{
  height: 100%;
  width: 33.33%;
  object-fit: cover;
}

.service-description{
  padding: 5% 2.5% 5% 0%;
  width: 100%;
  background-color: #2B2D42;
  /* height: 100%; */
}

.service-description h1{
  padding: 0px 150px 0px 50px;
}

.ext-int-header h1{
  font-size: 50px;
}

.service-description p{
  padding: 0px 150px 0px 50px;
}

.service-description hr{
  margin: 20px 150px 20px 50px;
}

.ext-int-signs{
  /* height: 80vh; */
  padding-bottom: 31.5px;
  padding-top: 30px;
  background-color: #2B2D42;
  display: flex;
  flex-direction: row;
}

.portfolio-nav{
  height: 100%;
  margin-right: 20px;
  width: 20%;
}

.portfolio-grid{
  overflow: auto;
  height: 100vh;
  width: 80%;
  opacity: 1;
}

.portfolio-nav-mbl{
  width: 100%;
  height: auto;
  object-fit: cover;
  /* z-index: 2; */
  display: none;
}

.portfolio-nav-drawer{
  width: 100%;
  height: auto;
  background-color: #468189;
  object-fit: cover;
  display: none;
}

.portfolio-drawer-button{
  transition-duration: 500ms;
  background-color: #468189;
  height: auto;
  padding: 50px 0px;
  width: 100%;
  border: none;
  display: none;
  opacity: 1;
}

.drawer-close{
  display: block;
  opacity: 1;
  width: 100%;
  position: absolute;
  transition-duration: 500ms;
}

.drawer-open{
  display: block;
  opacity: 1;
  transition-duration: 500ms;
}

.drawer-close.hidden,
.drawer-open.hidden {
  opacity: 0;
}

.portfolio-nav-mbl {
  display: none;
  flex-direction: column;
}

.portfolio-nav-mbl.active {
  display: flex;
}

.quote-header{
  border: solid white;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
}

.quote-box{
  width: 40%;
  padding: 5% 5% 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-text{
  height: 100px;
  padding: 1.5vh 0vh 1.5vh 0vh;
}

.logo-button{
  height: 100%;
  background-color: transparent;
  border: none;
}

.quote-box h1{
  color: white;
  text-align: center;
  font-size: 40px;
  font-family: 'Roboto';
}

.quote-box h5{
  color: white;
}

.quote-box h3{
  margin-top: 20px;
  font-size: 30px;
}

.quote-box p{
  margin-top: 10px;
}

.portfolio-button{
  border-radius: 0px 20px 20px 0px;
  transition-duration: 100ms;
  font-size: 16px;
  background-color: #2B2D42;
  padding: 10px;
  width: 100%;
  border: none;
}

.portfolio-button h4{
  font-weight: 200;
}

.portfolio-button:hover{
  background-color: rgba(255, 255, 255, 0.05);
}

.portfolio-button:active{
  background-color: #48495B;
  border-radius:  0px 5px 5px 0px;
}

.portfolio-item{
  border: none;
  background-color: rgba(255, 255, 255, 0.0);
  margin-bottom: 10px;
  border-radius: 20px;
  /* max-height: 20%; */
  width: 100%;
  opacity: 1;
  transition-duration: 200ms;
  will-change: opacity, transform;
  transform: scale(1);
}

.portfolio-grid .portfolio-item:hover{
  transform: scale(0.9);
}

.portfolio-image{
  border-radius: 20px;
  opacity: 0;
  /* max-height: 20%; */
  width: 100%;
  transition-duration: 200ms;
  object-fit: cover;
  transition-timing-function: ease-in;
  will-change: opacity, transform;
  transform: scale(1);
  z-index:1;
}

.column{
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100%;
  float: left;
  width: 33.33%;
  padding-right: 10px;
}

.column::-webkit-scrollbar { 
  display: none; /* Chrome Safari */
}

.row{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-logo{
  margin: 0px;
  height: 80px;
  object-fit: contain;
}

.show {
  display: block;
}

.navbar.active {
  display: flex;
}

.portfolio-button.active {
  background-color: #48495B;
  color: white;
}

.expand-overlay{
  z-index: 1;
  border: none;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.5);
}

.overlay-img{
  height: 70%;
  width: 70%;
  object-fit: contain;
  src: "";
}

.g-recaptcha{
  display: flex;
  width: 100%;
  justify-content: center;
}

#contact-page{
  padding-top: 6vh;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

#permit-design-page{
  padding-top: 160px;
  padding-bottom: 31.5px;
  display: flex;
  min-height: 100vh;
  background-color: #48495B;
  flex-direction: row;
}

#homepage-logo{
  height: 30%;
  width: 30%;
  align-content: left;
}

#logo{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

#homepage-video{
  height: 95%;
  padding-top: 160px;
  position: relative;
  overflow: hidden;
}

.home-image{
  opacity: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
  transition-duration: 500ms;
  position: absolute;
}

#welcome{
  z-index: 0;
  height: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2.5% 0%;
}

#overlay-text{
  margin-top: 30px;
}

#subtext{
  margin-top: 20px;
}

#subtext h2{
  margin-bottom: 10px;
}

#homepage-description{
  /* height: 100%; */
  padding: 2% 0% 0% 0%;
  margin-bottom: 31.5px;
  display: flex;
  background-color: #2B2D42;
  /* align-items: center; */
  flex-direction: row;
}

#description{
  padding-left: 20px;
  padding-right: 100px;
  padding-bottom: 60px;
  width: 40%;
  /* height: 100%; */
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}

#description-header{
  padding-top: 20px;
  padding-bottom: 10px;
}

#description-list{
  padding-top: 20px;
}

#description-column{
  padding-top: 20px;
}

#description-list-tab{
  display: none;
  justify-content: space-around;
  flex-direction: row;
}

#description-graphics{
  /* padding-top: 60px; */
  width: 60%;
  height: 90vh;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  justify-content: flex-end;
  clear: both;
}

#video-box{
  display: none;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
}

#orword{
  color: #D67F0D;
}

#home-header{
  padding: 0px 150px 50px 150px;
}

#home-header h1{
  font-size: 28px;
}

#welcome h1{
  font-size: 60px;
}

#welcome h2{
  font-size: 20px;
}

#description h1{
  font-size: 24px;
  padding-bottom: 10px;
}

#description h2{
  font-size: 20px;
  padding: 10px;
}

footer{
  width: 100%;
  margin-top: -31.5px;
  height: 31.5px;
}

#contact-header{
  width: 50%;
  padding: 50px;
  background-color: #2B2D42;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#contact-header h1{
  font-size: 60px;
}

#contact-header h3{
  margin-top: 20px;
  font-size: 20px;
}

#contact-header p{
  margin-top: 10px;
}

#contact-box{
  padding: 10% 10% 5% 10%;
  margin-bottom: 31.5px;
  width: 50%;
  background-color: #E6E6E6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#contact-box button{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition-duration: 500ms;
  width: 60%;
  height: 50px;
  margin-top: 15px;
  padding: 10px;
  border: none;
  color: white;
  background-color: #D67F0D;
  border-radius: 20px;
  font-family: 'Roboto';
  font-size: 18px;
}

#contact-box button:hover {
  background-color: orange;
}

#contact-box button:active {
  background-color: #D67F0D;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0);   
}

@media only screen and (max-width: 1024px) {
  /* For mobile: */
  #homepage-video{
      padding-top: 0vh;
      height: auto;
  }

  #homepage-logo{
          height: 80%;
          width: 80%;
          margin-top: 20px;
          margin-bottom: 60px;
  }
  
  .center-text{
      text-align: left;
  }

  .header-mobile{
      display: block;
  }

  .header-norm{
      display: none;
  }

  #welcome{
      height: auto;
      padding: 20% 10% 10% 10%;
  }

  #welcome h1{
      font-size: 18px;
  }

  #welcome h2{
      font-size: 16px;
  }

  #video-box {
      display: none;
  }

  #homepage-description{
      margin-top: 0;
      flex-direction:column;
      height:auto;
  }

  #homepage-description h1{
      font-size: 16px;
  }

  #homepage-description h2{
      font-size: 16px;
      padding-left: 0px;
  }

  #description-graphics{
      padding-top: 7.5%;
      width: 100%;
      height: 30vh;
      justify-content: center;
  }

  #description{
      width: 100%;
      padding: 5% 10% 10% 10%;
      height: auto;
      justify-content: flex-start;
  }

  .ext-int-header{
      flex-direction: column-reverse;
      padding-top: 6vh;
  }

  .ext-int-header h1{
  font-size: calc(34px + 20 * ((100vw - 600px) / 424));
  padding: 7.5% 5%;
  }

  .quote-box{
      width: 100%;
      height: 100%;
      padding: 5% 15% 5% 15%;
  }

  .quote-box h1{
      font-size: 30px;
  }

  .quote-box h5{
      font-size: 18px;
      padding-bottom: 10px;
  }

  .quote-box p{
      font-size: 35px;
      padding-bottom: 10px;
  }

  .quote-box button{
      font-size: calc(30px + 10 * ((100vw - 600px) / 424));
      height: auto;
      width: 50%;
      margin-top: 0px;
      border-radius: 60px;
      padding: 15px;
  }

  .quote-box .quote-header{
      border-radius: 50px;
      margin-bottom: 10%;
      border: solid 5px white;
      padding: 30px;
  }

  .quote-box .small-box{
      border-radius: 20px;
      font-size: 14px;
      height: 6vh;
  }

  .quote-box .last-box{
      border-radius: 20px;
      height: 8vh;
      margin-bottom: 10%;
      font-size: 14px;
  }

  .service-description{
      width: 100%;
      height: 100%;
      font-size: 24px;
      padding: 10% 0% 10% 0%;
  }

  .service-description h1{
      font-size: 22px;
      padding: 0% 13% 0% 13%;
  }

  .ext-int-signs{
      height: 91%;
      overflow: auto;
      flex-direction: column;
      padding-top: 0px;
  }

  .graphic-bar{
      width: 100%;
      height: 35vh;
      margin: 5% 0% 5% 0%;
  }

  .portfolio-button{
      padding: 3% 0%;
      border-radius: 0px;
      font-size: 30px;
  }

  #permit-design-page{
      padding-top: 6vh;
      flex-direction: column-reverse;
  }

  #contact-page{
      padding-top: 6vh;
      flex-direction: column;
  }

  #contact-box{
      /* height: 100%; */
      width: 100%;
  }

  #contact-box .small-box{
      height: 6vh;
      border-radius: 20px;
      font-size: 14px;
  }
 
  #contact-box .last-box{
      height: 8vh;
      margin-bottom: 20px;
      border-radius: 20px;
      font-size: 14px;
  }

  #contact-box h5{
      font-size: 18px;
  }

  #contact-box button{
      font-size: calc(30px + 10 * ((100vw - 600px) / 424));
      height: auto;
      width: 50%;
      margin-top: 0px;
      border-radius: 60px;
      padding: 15px;
  }

  #contact-header{
      width: 100%;
      padding: 10% 13% 10% 13%;
  }

  #contact-header h1{
      font-size: 22px;
  }

  #contact-header p{
      font-size: 14px;
  }

  .g-recaptcha{
      transform: scale(1.0);
      margin-bottom: 30px;
  }

  .portfolio-image{
      height: 100%;
      width: 100%;
      margin-bottom: -4px;
      border-radius: 0px;
  }

  .portfolio-item{
      border-radius: 0px;
      margin-bottom: 0px;
      height: 20vh;
  }

  .column{
      overflow: revert;
      padding-right: 0px;
      /* height: 100vh; */
  }

  .portfolio-nav{
      width: 100%;
      height: auto;
      display: none;
  }

  .portfolio-grid{
      width: 100%;
      height: 80vh;
      /* padding-left: 10px; */
  }

  .portfolio-nav-drawer{
      width: 100%;
      background-color: #2B2D42;
      object-fit: cover;
      opacity: 1;
      display: block;
  }
  
  .portfolio-drawer-button{
      display: block;
      padding: 3% 0%;
  }

  .portfolio-nav-drawer{
      display: block;
  }

  .portfolio-drawer-button h4{
      font-size: 24px;
      font-weight: 200;
  }

  .portfolio-button h4{
      font-size: 24px;
      font-weight: 200;
  }

  .navbar {
      width: 100%;
      height: auto;
      padding: 3% 0%;
      display: none;
      flex-direction: column;
      border: solid 1px;
      border-color: #D67F0D;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }

  .navbutton{
      width: 100%;
      padding-left: 7%;
      height: 5vh;
      text-align: left;
  }

  .navbutton h2{
      font-size: 16px;
  }

  .navbar-toggle{
      display: block;
  }

  body header{
      height: 6vh;
  }

  .logo-text{
      height: 100%;
  }

  .center-text p{
      font-size: 18px;
  }

  hr {
      margin-top: 1%;
      margin-bottom: 1%;
  }

  #description-header {
      padding-top: 2%;
      padding-bottom: 2%;
  }
  
  #description-list {
      padding-top: 2%;
  }

  #overlay-text {
      margin-top: 5%;
  }

  .service-description p {
      font-size: 14px;
      padding: 0% 13% 0% 13%;
  }

  .service-description hr {
      margin: 5% 13% 5% 13%;
  }

  .copyright{
      padding: 2%;
      font-size: 10px;
  }

  footer{
      /* height: auto; */
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px){
  .service-description hr{
      margin-top: 3%;
      margin-bottom: 3%;
  }

  .service-description h1{
      font-size: calc(30px + 20 * ((100vw - 600px) / 424));
  }

  #welcome h1{
      font-size: calc(24px + 20 * ((100vw - 600px) / 424));
  }

  #homepage-logo{
      height: 80%;
  }

  #description-list-tab{
      display: flex;
  }

  #description-list{
      display: none;
  }

  .quote-box button{
      padding: 20px;
  }

  .contact-box button{
      padding: 20px;
  }

}

@media only screen and (max-width: 600px){
  #welcome h1{
      font-size: calc(34px + 20 * ((100vw - 600px) / 424));
  }

  .g-recaptcha{
      transform: scale(0.8);
  }
}

.home-image {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.home-image.active {
  opacity: 1;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Make the container full height */
  background-color: #f4f4f4; /* Light background color */
  padding: 20px; /* Padding around the container */
}

.login h2 {
  margin-bottom: 20px;
  color: #333; /* Darker color for the heading */
}

.login form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; /* Max width for the form */
  background: white;
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.login label {
  margin-bottom: 8px;
  color: #555; /* Slightly darker color for labels */
}

.login input {
  margin-bottom: 16px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 16px;
}

.login button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff; /* Blue background color */
  border: none;
  border-radius: 4px; /* Slightly rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.cms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding: 20px;
}

.cms h2 {
  margin-bottom: 20px;
  color: #333;
  padding-top: 190px;
}

.cms h3 {
  margin-bottom: 20px;
  color: #333;
}

.text-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.text-fields .form-group {
  margin-bottom: 16px;
}

.text-fields label {
  margin-bottom: 8px;
  color: #555;
}

.text-fields textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.text-fields button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.text-fields button:hover {
  background-color: #0056b3;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.image-upload h3 {
  margin-bottom: 10px;
}

.image-upload input {
  margin-bottom: 10px;
}

.image-upload button {
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-upload button:hover {
  background-color: #0056b3;
}

.image-gallery {
  width: 100%;
}

.image-gallery h3 {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.image-item {
  text-align: center;
}

.image-item img {
  max-width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-item p {
  margin-top: 10px;
  color: #555;
}

/* Portfolio animations */

.portfolio-image {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.portfolio-image.show {
  opacity: 1;
}

.navbar.active {
  display: flex;
}



/* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */